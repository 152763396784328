// import { useContext } from "react";

import HeroImg from "../../assets/mainItems/heroImg.png";
import EmailSignupForm from "../../components/EmailSignupComponents/EmailSignupForm";
import "./EmailSignup.css";

// import WebplaceContext from "../../store/webplace-context";

const EmailSignup = () => {


    return (
        <div className="emailsignup-main-wrapper">
            <img src={HeroImg} alt="main show food" className="image-style" />
            <div className="emailsignup-title">Contact Us</div>
            <div className="emailsignup-content-wrapper">
                <EmailSignupForm />
            </div>
        </div>
    );
};

export default EmailSignup;
